import axios from 'axios'

// create an axios instance
const service = axios.create({
          // baseURL: 'http://localhost:8000/', // url = base url + request url
          // baseURL: '', // url = base url + request url
          baseURL: 'https://kh.esaliang.vip/', // url = base url + request url
          // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
          // withCredentials: true, // send cookies when cross-domain requests
          timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
          config => {
                    // do something before request is sent
                    // let token = getToken()
                    // if (token) {
                    //   // let each request carry token
                    //   // ['X-Token'] is a custom headers key
                    //   // please modify it according to the actual situation
                    //   config.headers['authorization'] = token
                    // }
                    return config
          },
          error => {
                    // do something with request error
                    console.log(error) // for debug
                    return Promise.reject(error)
          }
)

// response interceptor
service.interceptors.response.use(
          /**
           * If you want to get http information such as headers or status
           * Please return  response => response
           */

          /**
           * Determine the request status by custom code
           * Here is just an example
           * You can also judge the status by HTTP Status Code
           */
          response => {
                    const res = response.data
                    // if the custom code is not 20000, it is judged as an error.
                    if (res.code !== 200) {
                              alert(res.msg)
                              return Promise.reject(new Error(res.message || 'Error'))
                    } else {
                              return res
                    }
          },
          error => {
                    console.log('err' + error) // for debug
                    alert(error.message)
                    return Promise.reject(error)
          }
)

export default service