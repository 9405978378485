import {
          createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import {
          createI18n
} from 'vue-i18n'

const app = createApp(App)
app.use(router)


let locale = navigator.language || 'en-US'
locale = locale.slice(0, 2)
let lanList = ['en', 'fr', 'de', 'it', 'pt', 'es']
if (!lanList.includes(locale)) locale = 'en'
app.config.globalProperties.$locale = locale
const i18n = createI18n({
          locale,
          messages: {
                    'en': { // 英语 
                              welcome: "Our passionate team is dedicated to providing you with a stress-free shopping experience with our 100%Confidence Guarantee.Please contact us on Amazon's messaging system.",
                              resgister: "Welcome to our campaign.Please kindly fill in your order ID, and we can confirm your information and send you a new shapewear.",
                              orderNoLabel: 'Please fill in Order Number',
                              userNameLabel: 'Your Name',
                              eMailLabel: 'E-Mail',
                              'Lifetime Warranty Terms': 'Lifetime Warranty Terms',
                              'All Of our products come With a Lifetime Replacement Guarantee.For ANY quality-related issues,we Will send you a replacement for free!': 'All Of our products come With a Lifetime Replacement Guarantee.For ANY quality-related issues,we Will send you a replacement for free!',
                              'SIGN UP': 'Next',
                              'CONTACT US': 'CONTACT US',
                              'If you have any questions. please send an email and our customer service representative will reply you as soon as possible!': 'If you have any questions. please send an email and our customer service representative will reply you as soon as possible!',
                              'Order No. cannot be blank': 'Order No. cannot be blank',
                              'Name cannot be empty': 'Name cannot be empty',
                              'Email cannot be empty': 'Email cannot be empty',
                              'please choose the product you like.': "Please select the option you want.",
                              "ATTENTION: For your account security, please don't attach pictures of the card when you leave a product review.": "ATTENTION: For your account security, please don't attach pictures of the card when you leave a product review.",
                              'please attach the screenshot of your rating or review on our product page. Your opinion will help other customers make a better purchase decision.': "[Required]Give us 5-star reviews and take a screenshot of the comment and upload.",
                              '(Note: Screenshot Of where you write your review only. Do not send product photos.)': 'To ensure fast cashing out of your prize, please submit the correct screenshot and check your email within 24 hours.',
                              "Address": 'Address',
                              "Postal Code": 'Postal Code',
                              "City": 'City',
                              "Phone": 'Phone',
                              "State": 'State',
                              "giftId": 'giftId',
                              "Submit": 'Submit',
                              "upload image": 'upload image',
                              "fill in your delivery address": 'fill in your delivery address',
                              "Address cannot be empty": 'Address cannot be empty',
                              "Postal Code cannot be empty": 'Postal Code cannot be empty',
                              "City cannot be empty": 'City cannot be empty',
                              "Phone cannot be empty": 'Phone cannot be empty',
                              "State cannot be empty": 'State cannot be empty',
                              "gift cannot be empty": 'gift cannot be empty',
                              "choice_gift": 'choice_gift',
                              "sumitfrom": 'sumit-from',
                              "please check the order num": 'please check the order num',
                              "network error": 'network error',
                              "USA": "USA",
                              "UK": "UK",
                              "Germany": "Germany",
                              "France": "France",
                              "Italy": "Italy",
                              "Spain": "Spain",
                              "Portugal": "Portugal",
                              "China": "China",

                              "to get a lifetime warranty.": "to get a lifetime VIP Service.",
                              "15DAYS": "15DAYS",
                              "Register your purchase within": "Register your purchase within",
                              "Click Here To": "CLICK HERE TO",
                              "country": "country",
                              "other": "other",
                              "country cannot be empty": "country cannot be empty",
                              "orderWarm": "Click here how to find your Order Number",
                    },
                    'zh111': { // 中文 不要了
                              welcome: '感谢您根据我们的VIP计划注册您的产品，并加入我们的VIP俱乐部。',
                              // resgister: '注册并终身保修',
                              orderNoLabel: '请填写订单号',
                              userNameLabel: '你的名字',
                              eMailLabel: '邮箱',
                              'Lifetime Warranty Terms': '终身保修条款',
                              'All Of our products come With a Lifetime Replacement Guarantee.For ANY quality-related issues,we Will send you a replacement for free!': '我们所有的产品都有终身更换保证。对于任何与质量有关的问题，我们将免费给您更换!',
                              'SIGN UP': '确认',
                              'CONTACT US': '联系我们',
                              'If you have any questions. please send an email and our customer service representative will reply you as soon as possible!': '如果你有任何问题。请发邮件给我们，我们的客服代表会尽快回复您!',
                              'Order No. cannot be blank': '订单号不能为空',
                              'Name cannot be empty': '姓名不能为空',
                              'Email cannot be empty': '邮箱不能为空',
                              'please choose the product you like.': '请选择您喜欢的产品.',
                              'please attach the screenshot of your rating or review on our product page. Your opinion will help other customers make a better purchase decision.': '请在我们的产品页面上附上您的评级或评论的截图。你的意见将有助于其他客户做出更好的购买决定.',
                              '(Note: Screenshot Of where you write your review only. Do not send product photos.)': '(注:截图你写评论的界面的界面。请勿发送产品照片。)',
                              "Address": '地址',
                              "Postal Code": '邮编',
                              "City": '城市',
                              "Phone": '电话',
                              "State": '省',
                              "giftId": 'giftId',
                              "Submit": '提交',
                              "upload image": '上传图片',
                              "fill in your delivery address": '填写你的收货地址',
                              "Address cannot be empty": '地址不能为空',
                              "Postal Code cannot be empty": '邮编不能为空',
                              "City cannot be empty": '城市不能为空',
                              "Phone cannot be empty": '电话不能为空',
                              "State cannot be empty": '省市不能为空',
                              "gift cannot be empty": '请选择礼物',
                              'choice_gift': '选择礼物',
                              "sumitfrom": '提交表单',
                              "please check the order num": '请检查订单号是否正确',
                              "USA": "美国",
                              "UK": "英国",
                              "Germany": "德国",
                              "France": "法国",
                              "Italy": "意大利",
                              "Spain": "西班牙",
                              "Portugal": "葡萄牙",
                              "China": "中国",

                              "to get a lifetime warranty.": "获得终身保修",
                              "15DAYS": "15DAYS",
                              "Register your purchase within": "注册您的商品",
                              "Click Here To": "==> 点 击 我 <==",
                              "country": "国家",
                              "other": "其他",
                              "country cannot be empty": "请选择国家",
                              "orderWarm": "Click here how to find your Order Number",
                    },
                    'fr': { // 法文
                              welcome: 'Merci d’enregistrer votre produit dans le cadre de notre programme de garantie et de rejoindre notre club VIP.',
                              resgister: "Bienvenue à nos événements. S'il vous plaît remplir votre id de commande, nous pouvons confirmer vos informations et vous envoyer un nouveau Bodysuit.",
                              orderNoLabel: 'Veuillez remplir le numéro de commande',
                              userNameLabel: 'votre nom',
                              eMailLabel: 'E-mail',
                              'Lifetime Warranty Terms': 'Conditions de garantie à vie',
                              'All Of our products come With a Lifetime Replacement Guarantee.For ANY quality-related issues,we Will send you a replacement for free!': 'Tous nos produits sont livrés avec une garantie de remplacement à vie. Pour TOUT problème lié à la qualité, nous vous enverrons un remplacement gratuitement!',
                              'SIGN UP': "Suivant",
                              'CONTACT US': 'Nous contacter contact',
                              'If you have any questions. please send an email and our customer service representative will reply you as soon as possible!': 'Si vous avez des questions. Envoyez-nous un courriel et notre représentant du service à la clientèle vous répondra dès que possible!',
                              'Order No. cannot be blank': 'Le numéro de commande ne peut pas être vide',
                              'Name cannot be empty': 'Le nom ne peut pas être vide',
                              'Email cannot be empty': 'L’email ne peut pas être vide',
                              'please choose the product you like.': "Veuillez sélectionner l'option que vous souhaitez.",
                              "ATTENTION: For your account security, please don't attach pictures of the card when you leave a product review.": "Remarque: pour la sécurité de votre compte, veuillez ne pas joindre une photo de la carte lorsque vous laissez un commentaire sur le produit.",
                              'please attach the screenshot of your rating or review on our product page. Your opinion will help other customers make a better purchase decision.': "[obligatoire] Donnez - nous une évaluation 5 étoiles et prenez une capture d'écran du commentaire et téléchargez - le.",
                              '(Note: Screenshot Of where you write your review only. Do not send product photos.)': "Pour vous assurer que vos gains sont encaissés rapidement, soumettez la capture d'écran correcte et vérifiez votre e - mail dans les 24 heures.",
                              "Address": 'adresse',
                              "Postal Code": 'Code postal',
                              "City": 'Les villes',
                              "Phone": 'Tél.',
                              "State": 'La province',
                              "giftId": 'giftId',
                              "Submit": 'Présenté par le',
                              "upload image": 'Télécharger une image',
                              "fill in your delivery address": 'Remplissez votre adresse de livraison',
                              "Address cannot be empty": 'L’adresse ne peut pas être vide',
                              "Postal Code cannot be empty": 'Le code postal ne peut pas être vide',
                              "City cannot be empty": 'La ville ne peut pas être vide',
                              "Phone cannot be empty": 'Le téléphone ne peut pas être vide',
                              "State cannot be empty": 'Province et ville ne peuvent pas être vides',
                              "gift cannot be empty": 'Veuillez choisir un cadeau',
                              'choice_gift': 'Choisissez un cadeau',
                              "sumitfrom": 'Soumettre le formulaire',
                              "please check the order num": 'Veuillez vérifier que le numéro de commande est correct',
                              "USA": "États-Unis",
                              "UK": "Royaume-Uni",
                              "Germany": "allemagne",
                              "France": "France",
                              "Italy": "Italie",
                              "Spain": "Espagne",
                              "Portugal": "Portugal",
                              "China": "Chine",

                              "to get a lifetime warranty.": "Obtenez une garantie à vie.",
                              "15DAYS": "15DAYS",
                              "Register your purchase within": "Inscrivez - vous à votre achat",
                              "Click Here To": "Cliquez ici",
                              "country": "Pays",
                              "other": "other",
                              "country cannot be empty": "Le pays ne peut pas être vide",
                              "orderWarm": "Cliquez ici pour trouver votre numéro de commande",

                    },
                    'de': { // 德语
                              welcome: 'Ja, danke, dass sie ihr produkt mit unserem garantieprogramm angemeldet haben und ihrem vip-club beitreten.',
                              resgister: "Willkommen zu unserer Kampagne. Bitte geben Sie Ihre Bestellnummer ein und wir können Ihre Daten bestätigen und Ihnen eine neue Shapewear zusenden.",
                              orderNoLabel: 'Bitte geben Sie die Bestellnummer ein',
                              userNameLabel: 'Dein Name',
                              eMailLabel: 'Briefkasten.',
                              'Lifetime Warranty Terms': 'Bedingungen für die lebenslange Garantie',
                              'All Of our products come With a Lifetime Replacement Guarantee.For ANY quality-related issues,we Will send you a replacement for free!': 'Alle unsere Produkte werden mit einer lebenslangen Ersatzgarantie geliefert. Bei Qualitätsproblemen senden wir Ihnen kostenlos einen Ersatz!',
                              'SIGN UP': 'Nächster',
                              'CONTACT US': 'Um uns zu kontaktieren.',
                              'If you have any questions. please send an email and our customer service representative will reply you as soon as possible!': 'Falls sie irgendwelche fragen haben. Schicken sie uns eine e-mail, unser kellner wird sie so schnell wie möglich kontaktieren!',
                              'Order No. cannot be blank': 'Die miornummern dürfen nicht Leer sein',
                              'Name cannot be empty': 'Der name ist nicht frei',
                              'Email cannot be empty': 'Der briefkasten darf nicht Leer sein',
                              'please choose the product you like.': "Bitte wählen Sie die gewünschte Option aus.",
                              "ATTENTION: For your account security, please don't attach pictures of the card when you leave a product review.": "ACHTUNG: Zur Sicherheit Ihres Kontos fügen Sie bitte keine Bilder der Karte bei, wenn Sie eine Produktbewertung abgeben.",
                              'please attach the screenshot of your rating or review on our product page. Your opinion will help other customers make a better purchase decision.': "[Erforderlich]Geben Sie uns 5-Sterne-Bewertungen und machen Sie einen Screenshot des Kommentars und laden Sie ihn hoch.",
                              '(Note: Screenshot Of where you write your review only. Do not send product photos.)': "Um eine schnelle Auszahlung Ihres Preises zu gewährleisten, reichen Sie bitte den richtigen Screenshot ein und überprüfen Sie Ihre E-Mail innerhalb von 24-Stunden.",
                              "Address": 'Die adresse.',
                              "Postal Code": 'postleitzahl',
                              "City": 'In städten.',
                              "Phone": 'Ach, ein anruf.',
                              "State": 'provinz',
                              "giftId": 'giftId',
                              "Submit": 'Eingereicht werden',
                              "upload image": 'Bild hochladen.',
                              "fill in your delivery address": 'Füllen sie ihre absendeadresse aus',
                              "Address cannot be empty": 'Die adresse ist nicht frei',
                              "Postal Code cannot be empty": 'Eine postleitzahl ist nicht frei',
                              "City cannot be empty": 'Die städte dürfen nicht Leer sein',
                              "Phone cannot be empty": 'Das telefon ist nicht Leer',
                              "State cannot be empty": 'Also, die provinz kann nicht Leer sein',
                              "gift cannot be empty": 'Also, die provinz kann nicht Leer sein',
                              'choice_gift': 'Du wählst die geschenke.',
                              "sumitfrom": 'Formulare ausfüllen,',
                              "please check the order num": 'Bitte überprüfen sie, ob die bestellnummer stimmt',
                              "USA": "USA",
                              "UK": "UK",
                              "Germany": "Deutschland",
                              "France": "Frankreich",
                              "Italy": "Italien",
                              "Spain": "Spanien",
                              "Portugal": "Portugal",
                              "China": "China",

                              "to get a lifetime warranty.": "Garantie à vie.",
                              "15DAYS": "15DAYS",
                              "Register your purchase within": "Inscrivez - vous à votre achat",
                              "Click Here To": "Cliquez ici",
                              "country": "Pays",
                              "other": "other",
                              "country cannot be empty": "Le pays ne peut pas être vide",
                              "orderWarm": "Klicken Sie hier, um Ihre Bestellnummer zu finden",
                    },
                    'it': { // 意大利
                              welcome: 'Grazie per aver registrato il vostro prodotto nel nostro sistema di garanzia e per aver aderito al nostro club VIP.',
                              resgister: "Benvenuto nella nostra campagna. Si prega gentilmente di compilare il vostro ID ordine, e possiamo confermare le vostre informazioni e inviarvi un nuovo shapewear.",
                              orderNoLabel: "Si prega di compilare il numero d'ordine",
                              userNameLabel: 'Il tuo nome',
                              eMailLabel: 'E-mail',
                              'Lifetime Warranty Terms': 'Termini di garanzia a vita',
                              'All Of our products come With a Lifetime Replacement Guarantee.For ANY quality-related issues,we Will send you a replacement for free!': 'Tutti i nostri prodotti sono dotati di una garanzia di sostituzione a vita. Per QUALSIASI problema relativo alla qualità, ti invieremo una sostituzione gratuita!',
                              'SIGN UP': 'Avanti',
                              'CONTACT US': 'contattateci',
                              'If you have any questions. please send an email and our customer service representative will reply you as soon as possible!': 'Se avete un problema. Per favore inviateci un’e-mail e i nostri rappresentanti in servizio vi risponderanno quanto prima!',
                              'Order No. cannot be blank': 'Il numero d’ordine non deve essere vuoto',
                              'Name cannot be empty': 'I nomi non devono restare vuoti',
                              'Email cannot be empty': 'La cassetta delle lettere non deve restare vuota',
                              'please choose the product you like.': "Seleziona l'opzione desiderata.",
                              "ATTENTION: For your account security, please don't attach pictures of the card when you leave a product review.": "ATTENZIONE: Per la sicurezza del tuo account, non allegare foto della carta quando lasci una recensione sul prodotto.",
                              'please attach the screenshot of your rating or review on our product page. Your opinion will help other customers make a better purchase decision.': "[Obbligatorio]Dacci recensioni a 5 stelle e fai uno screenshot del commento e carica.",
                              '(Note: Screenshot Of where you write your review only. Do not send product photos.)': "Per assicurarti un incasso veloce del tuo premio, invia lo screenshot corretto e controlla la tua email entro 24 ore.",
                              "Address": 'indirizzo',
                              "Postal Code": 'P.',
                              "City": 'città',
                              "Phone": 'telefono',
                              "State": 'provincia',
                              "giftId": 'giftId',
                              "Submit": 'presentazione',
                              "upload image": 'Immagine caricata',
                              "fill in your delivery address": 'Indirizzi utili',
                              "Address cannot be empty": 'Gli indirizzi non possono essere vuoti',
                              "Postal Code cannot be empty": 'Il codice postale non deve restare vuoto',
                              "City cannot be empty": 'Le città non possono essere vuote',
                              "Phone cannot be empty": 'Il telefono non può essere vuoto',
                              "State cannot be empty": 'Le città e i comuni non possono rimanere vuoti',
                              "gift cannot be empty": 'Scegliete i regali',
                              'choice_gift': 'Scegliere i regali',
                              "sumitfrom": 'Presentazione dei formulari',
                              "please check the order num": 'La prego di verificare se il numero d’ordine è corretto',
                              "USA": "USA",
                              "UK": "Regno Unito",
                              "Germany": "Germania",
                              "France": "Francia",
                              "Italy": "Italia",
                              "Spain": "Spagna",
                              "Portugal": "Portogallo",
                              "China": "Cina",

                              "to get a lifetime warranty.": "per ottenere una garanzia di vita.",
                              "15DAYS": "15DAYS",
                              "Register your purchase within": "Registra il tuo acquisto all'interno",
                              "Click Here To": "CLICK QUI PER",
                              "country": "Paese",
                              "other": "other",
                              "country cannot be empty": "paese non può essere vuoto",
                              "orderWarm": "Clicca qui come trovare il tuo numero d'ordine",
                    },
                    'pt': { // 葡萄牙
                              welcome: 'Obrigado por registar o seu produto de acordo com o nosso programa de garantia e aderir ao nosso clube VIP.',
                              resgister: "Bem-vindo à nossa campanha. Por favor, preencha o seu ID de pedido, e nós podemos confirmar suas informações e enviar-lhe um novo shapewear.",
                              orderNoLabel: 'Por favor, preencha o número do pedido',
                              userNameLabel: 'Seu primeiro nome',
                              eMailLabel: 'E-mail',
                              'Lifetime Warranty Terms': 'Termos de garantia vitalícia',
                              'All Of our products come With a Lifetime Replacement Guarantee.For ANY quality-related issues,we Will send you a replacement for free!': 'Todos os nossos produtos vêm com uma garantia de substituição vitalícia. Para todos os problemas relacionados à qualidade, nós enviar-lhe-emos a substituição para livre!',
                              'SIGN UP': 'Próximo',
                              'CONTACT US': 'Fale conosco fale conosco',
                              'If you have any questions. please send an email and our customer service representative will reply you as soon as possible!': 'Se você tiver alguma dúvida. Envie-nos um e-mail e um de nossos representantes de atendimento ao cliente irá responder o mais breve possível!',
                              'Order No. cannot be blank': 'O número do pedido não pode estar vazio',
                              'Name cannot be empty': 'Nome não pode estar em branco',
                              'Email cannot be empty': 'O email não pode estar vazio',
                              'please choose the product you like.': "Por favor, seleccione a opção que deseja.",
                              "ATTENTION: For your account security, please don't attach pictures of the card when you leave a product review.": "ATENÇÃO: Para a segurança da sua conta, por favor, não anexe fotos do cartão quando deixar uma avaliação do produto.",
                              'please attach the screenshot of your rating or review on our product page. Your opinion will help other customers make a better purchase decision.': "[Obrigatório]Dê-nos avaliações 5 estrelas e tire uma captura de tela do comentário e faça o upload.",
                              '(Note: Screenshot Of where you write your review only. Do not send product photos.)': "Para garantir um saque rápido do seu prêmio, envie a captura de tela correta e verifique seu e-mail dentro de 24 horas.",
                              "Address": 'endereço',
                              "Postal Code": 'Código postal',
                              "City": 'cidade',
                              "Phone": 'O telefone',
                              "State": 'província',
                              "giftId": 'giftId',
                              "Submit": 'apresentação',
                              "upload image": 'Carregar uma imagem',
                              "fill in your delivery address": 'Preencha o seu endereço de entrega',
                              "Address cannot be empty": 'O endereço não pode estar vazio',
                              "Postal Code cannot be empty": 'O código postal não pode estar vazio',
                              "City cannot be empty": 'A cidade não pode estar vazia',
                              "Phone cannot be empty": 'O telefone não pode estar vazio',
                              "State cannot be empty": 'Província e cidade não podem estar vazias',
                              "gift cannot be empty": 'Por favor selecione um presente',
                              'choice_gift': 'Escolha um presente',
                              "sumitfrom": 'Envie um formulário',
                              "please check the order num": 'Verifique se o número do pedido está correto',
                              "USA": "Estados Unidos",
                              "UK": "Reino Unido",
                              "Germany": "Alemanha",
                              "France": "França",
                              "Italy": "Itália",
                              "Spain": "Espanha",
                              "Portugal": "Portugal",
                              "China": "China",

                              "to get a lifetime warranty.": "país não pode estar vazio",
                              "15DAYS": "15DAYS",
                              "Register your purchase within": "Registar a sua compra no interior",
                              "Click Here To": "Clique aqui para",
                              "country": "País",
                              "other": "other",
                              "country cannot be empty": "país não pode estar vazio",
                              "orderWarm": "Clique aqui para encontrar o seu número de pedido",
                    },
                    'es': { // 西班牙
                              welcome: 'Gracias por registrar su producto bajo nuestro programa de garantía y unirse a nuestro club VIP.',
                              resgister: "Bienvenidos a nuestro evento. Por favor, rellene el ID de su pedido, podemos confirmar su información y enviarle una nueva prenda de plástico.",
                              orderNoLabel: 'Por favor complete el número de pedido',
                              userNameLabel: 'Tu nombre',
                              eMailLabel: 'E-mail',
                              'Lifetime Warranty Terms': 'Términos de la garantía de por vida',
                              'All Of our products come With a Lifetime Replacement Guarantee.For ANY quality-related issues,we Will send you a replacement for free!': 'Todos nuestros productos vienen con una garantía de reemplazo de por vida. ¡Para CUALQUIER problema relacionado con la calidad, le enviaremos un reemplazo gratis!',
                              'SIGN UP': 'Siguiente',
                              'CONTACT US': 'Contacta con nosotros',
                              'If you have any questions. please send an email and our customer service representative will reply you as soon as possible!': 'Si tiene alguna pregunta. Envíenos un correo electrónico y uno de nuestros representantes de servicio al cliente le responderá lo antes posible!',
                              'Order No. cannot be blank': 'El número de pedido no puede estar vacío',
                              'Name cannot be empty': 'El nombre no puede estar vacío',
                              'Email cannot be empty': 'El correo electrónico no puede estar vacío',
                              'please choose the product you like.': "Por favor, elija la opción que desea.",
                              "ATTENTION: For your account security, please don't attach pictures of the card when you leave a product review.": "Nota: por la seguridad de su cuenta, no adjunte una imagen de la tarjeta al dejar un comentario del producto.",
                              'please attach the screenshot of your rating or review on our product page. Your opinion will help other customers make a better purchase decision.': "[necesario] danos una evaluación de cinco estrellas y captura de pantalla para comentar y cargar.",
                              '(Note: Screenshot Of where you write your review only. Do not send product photos.)': "Para asegurarse de que su bono se cobra rápidamente, envíe la captura de pantalla correcta dentro de las 24 horas y revise su correo electrónico.",
                              "Address": 'dirección',
                              "Postal Code": 'Código postal',
                              "City": 'La ciudad',
                              "Phone": 'Tel.',
                              "State": 'La provincia',
                              "giftId": 'giftId',
                              "Submit": 'presentación',
                              "upload image": 'Sube una imagen',
                              "fill in your delivery address": 'Complete su dirección de envío',
                              "Address cannot be empty": 'La dirección no puede estar vacía',
                              "Postal Code cannot be empty": 'El código postal no puede estar vacío',
                              "City cannot be empty": 'La ciudad no puede estar vacía',
                              "Phone cannot be empty": 'El teléfono no puede estar vacío',
                              "State cannot be empty": 'Provincia y ciudad no pueden estar vacías',
                              "gift cannot be empty": 'Por favor seleccione un regalo',
                              'choice_gift': 'Elige un regalo',
                              "sumitfrom": 'Enviar un formulario',
                              "please check the order num": 'Verifique se o número do pedido está correto',
                              "USA": "Estados Unidos",
                              "UK": "Reino Unido",
                              "Germany": "Alemania",
                              "France": "Francia",
                              "Italy": "Italia",
                              "Spain": "España",
                              "Portugal": "Portugal",
                              "China": "China",

                              "to get a lifetime warranty.": "per avere una garanzia di vita.",
                              "15DAYS": "15DAYS",
                              "Register your purchase within": "Registra il tuo acquisto all'interno",
                              "Click Here To": "CLICK QUI PER",
                              "country": "Paese",
                              "other": "other",
                              "country cannot be empty": "paese non può essere vuoto",
                              "orderWarm": "Haga clic aquí para encontrar su número de pedido",
                    }
          }
})
app.use(i18n)

app.mount('#app')